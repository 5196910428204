<template>
    <!-- 项目列表 -->
    <div class="mod-config">
        <div class="projectTitle">监理项目列表</div>
        <el-card body-style="padding-bottom: 0">
            <div class="title">项目信息</div>
            <div class="line"></div>
            <div class="line"></div>
            <el-form ref="form" :model="form" inline label-position="left">
                <el-row
                    style="background-color: rgb(243, 247, 247);margin-top: -20px;margin-left: -20px;height: 45px;width: 103%;">
                    <el-col :span="20" style="margin-left:20px">
                        <el-form-item label="工作控制号" prop="workNo" label-width="82px" class="selFormItem">
                            <el-input class="selSearchInput" type="input" v-model="workNo" placeholder="请输入内容"
                                clearable></el-input>
                        </el-form-item>
                        <el-form-item label="项目名称" prop="projectName" class="selFormItem">
                            <el-input class="selSearchInput" type="input" v-model="projectName" placeholder="请输入内容"
                                clearable></el-input>
                        </el-form-item>
                        <el-form-item label="所属单位" prop="companyId" class="selFormItem">
                            <el-select class="selSearchInput" v-model="companyId" placeholder="请选择" clearable>
                                <el-option v-for="item in companyList" :key="item.displayValue" :label="item.displayName"
                                    :value="item.displayValue">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="年度" prop="nd" class="selFormItem">
                            <el-select class="selYearInput" v-model="nd" placeholder="请选择" clearable>
                                <el-option v-for="(nd, index) in ndList" :key="nd" :label="nd" :value="nd">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="业务类别" prop="businessCode" class="selFormItem">
                            <el-select class="selSearchInput" v-model="businessCode" placeholder="请选择" clearable>
                                <el-option v-for="item in buinessCodeList" :key="item.displayValue"
                                    :label="item.displayName" :value="item.displayValue">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="selBtnGroup" style="margin-top:5px">
                        <el-button v-preventReClick class="btn" type="primary" size="small"
                            @click="selGetDataList()">查询</el-button>
                        <el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <div class="line" style="margin-top:-2px;margin-bottom:0px"></div>
            <el-row>
                <el-col :span="24" style="margin-top:-20px;margin-bottom:-2px;margin-left: -20px;">
                    <el-tabs v-model="activeIndex" type="card" @tab-click="handleTabClick">
                        <el-tab-pane :label="item.label" :key="item.name" v-for="item in tabs" :name="item.name">
                            <span slot="label" style="padding: 8PX">
                                <span v-if="item.name === 'interestProject'"><i class="el-icon-star-off"></i>{{ item.label
                                }}</span>
                                <span v-else>{{ item.label }}</span>
                            </span>
                        </el-tab-pane>
                    </el-tabs>
                </el-col>
            </el-row>
        </el-card>
        <div style="margin-top: -15px; height: 100%;">
            <el-card v-show="activeIndex === 'allProject'" style="margin-top: -15px; height: 560px;"
                body-style="height:520px; padding: 0;">
                <el-row style="margin-bottom: 10px;">
                    <el-table height="500" ref="dataListTable" :row-class-name="tableRowClassName" class="dataListTable"
                        :data="allProjectList" header-align="center" border :cell-style="{ padding: '0px' }"
                        header-cell-class-name="dataListTHeader" style="margin-bottom: 20px;">
                        <el-table-column type="index" label="No." width="60" align="center">
                            <template slot-scope="scope">
                                {{ (allProjectPageIndex - 1) * pageSize + scope.$index + 1 }}
                            </template>
                        </el-table-column>
                        <el-table-column label="" width="60" align="center">
                            <template slot-scope="scope">
                                <i class="el-icon-collection-tag" v-if="scope.row.useFlg === '1'"></i>
                            </template>
                        </el-table-column>
                        <el-table-column prop="businessName" label="业务类型" align="center" width="150">
                        </el-table-column>
                        <el-table-column prop="workNo" label="工作控制号" align="left" width="200">
                            <template slot-scope="scope">
                                <el-button type="text" size="medium" @click="selectedProject(scope.row.workNo)">{{
                                    scope.row.workNo }}</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="projectName" label="项目名称" align="left" width="300" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="projectEntrust" label="委托方" align="left" min-width="300"
                            show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="projectBeginTime" label="开工时间" align="center" width="120">
                            <template slot-scope="scope">
                                {{ scope.row.projectBeginTime | formatDate('yyyy-MM-dd') }}</template>
                        </el-table-column>
                        <el-table-column prop="projectFinishTime" label="完工时间" align="center" width="120">
                            <template slot-scope="scope">
                                {{ scope.row.projectFinishTime | formatDate('yyyy-MM-dd') }}</template>
                        </el-table-column>
                        <el-table-column prop="projectDirector" label="总监" align="center" width="100">
                        </el-table-column>
                        <el-table-column label="" width="160" align="left">
                            <template slot-scope="scope">
                                <el-button v-preventReClick type="text" size="mini" style="margin-left: 23px;"
                                    v-if="scope.row.followStatus === '0'"
                                    @click="savePersonProject(scope.row.workNo)">关注</el-button>
                                <el-button v-preventReClick type="text" size="mini" style="margin-left: 23px;"
                                    v-if="scope.row.followStatus === '1'"
                                    @click="deletePersonProject(scope.row.recId)">取关</el-button>
                                <el-button v-preventReClick type="text" size="mini"
                                    @click="setSubject(scope.row.workNo)">标的物</el-button>
                                <el-button v-preventReClick type="text" size="mini"
                                    @click="setProjectKind(scope.row.workNo)">设置</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="my-pagination" @size-change="sizeChangeHandle1"
                        @current-change="currentChangeHandle1" :current-page="allProjectPageIndex"
                        :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="allProjectTotalPage"
                        layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </el-row>
            </el-card>
        </div>
        <div>
            <el-card v-show="activeIndex === 'interestProject'" style="margin-top: -15px; height: 560px;"
                body-style="height:520px; padding: 0;">
                <el-row style="margin-bottom: 10px;">
                    <el-table height="500" :row-class-name="tableRowClassName" ref="dataListTable" class="dataListTable"
                        :data="interestProjectList" header-align="center" border :cell-style="{ padding: '0px' }"
                        header-cell-class-name="dataListTHeader" style="margin-bottom: 20px;">
                        <el-table-column type="index" label="No." width="60" align="center">
                            <template slot-scope="scope">{{ (interestProjectPageIndex - 1) * pageSize + scope.$index
                                + 1
                            }}</template>
                        </el-table-column>
                        <el-table-column label="" width="60" align="center">
                            <template slot-scope="scope">
                                <i class="el-icon-collection-tag" v-if="scope.row.useFlg === '1'"></i>
                            </template>
                        </el-table-column>
                        <el-table-column prop="businessName" label="业务类型" align="center" width="150">
                        </el-table-column>
                        <el-table-column prop="workNo" label="工作控制号" align="left" width="200">
                            <template slot-scope="scope">
                                <el-button type="text" size="medium" @click="selectedProject(scope.row.workNo)">{{
                                    scope.row.workNo }}</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="projectName" label="项目名称" align="left" width="300" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="projectEntrust" label="委托方" align="left" min-width="300"
                            show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="projectBeginTime" label="开工时间" align="center" width="100">
                            <template slot-scope="scope">
                                {{ scope.row.projectBeginTime | formatDate('yyyy-MM-dd') }}</template>
                        </el-table-column>
                        <el-table-column prop="projectFinishTime" label="完工时间" align="center" width="100">
                            <template slot-scope="scope">
                                {{ scope.row.projectFinishTime | formatDate('yyyy-MM-dd') }}</template>
                        </el-table-column>
                        <el-table-column prop="projectDirector" label="总监" align="center" width="120">
                        </el-table-column>
                        <el-table-column label="" width="170" align="left">
                            <template slot-scope="scope">
                                <el-button v-preventReClick type="text" size="mini" style="margin-left: 23px;"
                                    @click="deletePersonProject(scope.row.recId)">取关</el-button>
                                <el-button v-preventReClick type="text" size="mini"
                                    @click="setSubject(scope.row.workNo)">标的物</el-button>
                                <el-button v-preventReClick type="text" size="mini"
                                    @click="setProjectKind(scope.row.workNo)">设置</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="my-pagination" @size-change="sizeChangeHandle2"
                        @current-change="currentChangeHandle2" :current-page="interestProjectPageIndex"
                        :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="interestProjectTotalPage"
                        layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </el-row>
            </el-card>
        </div>
        <div style="margin-top: -15px; height: 100%;">
            <el-card v-show="activeIndex === 'myProject'" style="margin-top: -15px; height: 560px;"
                body-style="height:520px; padding: 0;">
                <el-row style="margin-bottom: 10px;">
                    <el-table height="500" :row-class-name="tableRowClassName" ref="dataListTable" class="dataListTable"
                        :data="myProjectList" header-align="center" border :cell-style="{ padding: '0px' }"
                        header-cell-class-name="dataListTHeader" style="margin-bottom: 20px;">
                        <el-table-column type="index" label="No." width="60" align="center">
                            <template slot-scope="scope">{{ (myProjectPageIndex - 1) * pageSize + scope.$index + 1
                            }}</template>
                        </el-table-column>
                        <el-table-column label="" width="60" align="center">
                            <template slot-scope="scope">
                                <i class="el-icon-collection-tag" v-if="scope.row.useFlg === '1'"></i>
                            </template>
                        </el-table-column>
                        <el-table-column prop="businessName" label="业务类型" align="center" width="150">
                        </el-table-column>
                        <el-table-column prop="workNo" label="工作控制号" align="left" width="200">
                            <template slot-scope="scope">
                                <el-button type="text" size="medium" @click="selectedProject(scope.row.workNo)">{{
                                    scope.row.workNo }}</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="projectName" label="项目名称" align="left" width="300" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="projectEntrust" label="委托方" align="left" min-width="300"
                            show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="projectBeginTime" label="开工时间" align="center" width="100">
                            <template slot-scope="scope">
                                {{ scope.row.projectBeginTime | formatDate('yyyy-MM-dd') }}</template>
                        </el-table-column>
                        <el-table-column prop="projectFinishTime" label="完工时间" align="center" width="100">
                            <template slot-scope="scope">
                                {{ scope.row.projectFinishTime | formatDate('yyyy-MM-dd') }}</template>
                        </el-table-column>
                        <el-table-column prop="projectDirector" label="总监" align="center" width="150">
                        </el-table-column>
                        <el-table-column label="" width="130" align="left">
                            <template slot-scope="scope">
                                <el-button v-preventReClick type="text" size="mini"
                                    @click="setSubject(scope.row.workNo)">标的物</el-button>
                                <el-button v-preventReClick type="text" size="mini"
                                    @click="setProjectKind(scope.row.workNo)">设置</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="my-pagination" @size-change="sizeChangeHandle3"
                        @current-change="currentChangeHandle3" :current-page="myProjectPageIndex"
                        :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="myProjectTotalPage"
                        layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </el-row>
            </el-card>
        </div>
        <project-kind-setting v-if="projectKindSettingVisible" ref="projectKindSetting"
            @refreshDataList="getDataList()"></project-kind-setting>
        <set-subject v-if="setSubjectVisible" ref="setSubject" @refreshDataList="getDataList()"></set-subject>
    </div>
</template>
<script>
import $common from "@/utils/common";
import ProjectKindSetting from './project-kind-setting'
import SetSubject from './subjectList-add-or-update'

export default {
    data() {
        return {
            showSearch: false,
            showDownload: false,
            showAdd: false,
            showEdit: false,
            showDelete: false,
            showCheck: false,
            showArchive: false,
            showRowDownload: false,
            pageSpan: 12,
            addOrUpdateVisible: false,
            form: {},
            workNo: '',
            projectName: '',
            nd: '',
            businessCode: '',
            companyId: '',
            companyList: [],
            buinessCodeList: [],
            totalCount: 0,
            //当前页码
            pageIndex: 1,
            // 显示序号
            pageIndex2: 1,
            //当前记录数
            pageSize: 20,
            //总页数
            inspectionList: [],
            testRecordList: [],

            // 所有项目
            allProjectList: [],
            allProjectTotalPage: 0,
            allProjectPageIndex: 1,

            // 我的关注项目
            interestProjectList: [],
            interestProjectTotalPage: 0,
            interestProjectPageIndex: 1,

            // 我的项目
            myProjectList: [],
            myProjectTotalPage: 0,
            myProjectPageIndex: 1,

            activeIndex: 'myProject',
            tabs: [
                { name: 'myProject', label: '我的项目' },
                { name: 'interestProject', label: '我的关注' },
                { name: 'allProject', label: '所有项目' }
            ],
            ndList: [],
            subjectList: [],
            projectKindSettingVisible: false,
            setSubjectVisible: false
        };
    },
    components: {
        ProjectKindSetting,
        SetSubject
    },
    filters: {
        formatDate: function (value, args) {
            if (!value) {
                return ''
            }
            var dt = new Date(value);
            if (args == 'yyyy-M-d') {// yyyy-M-d
                let year = dt.getFullYear();
                let month = dt.getMonth() + 1;
                let date = dt.getDate();
                return `${year}-${month}-${date}`;
            } else if (args == 'yyyy-M-d H:m:s') {// yyyy-M-d H:m:s
                let year = dt.getFullYear();
                let month = dt.getMonth() + 1;
                let date = dt.getDate();
                let hour = dt.getHours();
                let minute = dt.getMinutes();
                let second = dt.getSeconds();
                return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
            } else if (args == 'yyyy-MM-dd') {// yyyy-MM-dd
                let year = dt.getFullYear();
                let month = (dt.getMonth() + 1).toString().padStart(2, '0');
                let date = dt.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${date}`;
            } else {// yyyy-MM-dd HH:mm:ss
                let year = dt.getFullYear();
                let month = (dt.getMonth() + 1).toString().padStart(2, '0');
                let date = dt.getDate().toString().padStart(2, '0');
                let hour = dt.getHours().toString().padStart(2, '0');
                let minute = dt.getMinutes().toString().padStart(2, '0');
                let second = dt.getSeconds().toString().padStart(2, '0');
                return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
            }
        }
    },
    mounted() {
        this.getNdList();
        this.getAllBusinessCodeList();
        this.getAllCompanyList();
        this.getDataList();
        this.pageIndex2 = 1;
    },
    methods: {
        //重置
        resetFormData() {
            this.workNo = ''
            this.projectName = ''
            this.companyId = ''
            this.nd = ''
            this.businessCode = ''
            this.allProjectPageIndex = 1
            this.interestProjectPageIndex = 1
            this.myProjectPageIndex = 1
            this.getDataList();
        },
        getDataList() {
            // 所有项目
            this.$http({
                url: this.$store.state.httpUrl + "/business/projectsupervisorinfo/getAllProjectList",
                method: 'get',
                params: this.$http.adornParams({
                    limit: this.pageSize,
                    page: this.allProjectPageIndex,
                    workNo: this.workNo,
                    projectName: this.projectName,
                    companyId: this.companyId,
                    nd: this.nd,
                    businessCode: this.businessCode,
                })
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.allProjectList = data.body.records;
                    this.allProjectTotalPage = data.body.totalCount;
                }
            })

            // 我的关注项目
            this.$http({
                url: this.$store.state.httpUrl + "/business/projectsupervisorinfo/getInterestProjectList",
                method: 'get',
                params: this.$http.adornParams({
                    limit: this.pageSize,
                    page: this.interestProjectPageIndex,
                    workNo: this.workNo,
                    projectName: this.projectName,
                    companyId: this.companyId,
                    nd: this.nd,
                    businessCode: this.businessCode,
                })
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.interestProjectList = data.body.records;
                    this.interestProjectTotalPage = data.body.totalCount;
                }
            })

            // 我的项目（参与）
            this.$http({
                url: this.$store.state.httpUrl + "/business/projectsupervisorinfo/getMyProjectList",
                method: 'get',
                params: this.$http.adornParams({
                    limit: this.pageSize,
                    page: this.myProjectPageIndex,
                    workNo: this.workNo,
                    projectName: this.projectName,
                    companyId: this.companyId,
                    nd: this.nd,
                    businessCode: this.businessCode,
                })
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.myProjectList = data.body.records;
                    this.myProjectTotalPage = data.body.totalCount;
                }
            })
        },
        getAllCompanyList() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/ccsiorganizeinfo/getAllCompanyList",
                method: 'get',
                params: this.$http.adornParams({})
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.companyList = data.body;

                }
            })
        },
        // 年度
        getNdList() {
            this.ndList.push(new Date().getFullYear() - 3);
            this.ndList.push(new Date().getFullYear() - 2);
            this.ndList.push(new Date().getFullYear() - 1);
            this.ndList.push(new Date().getFullYear());
        },
        getAllBusinessCodeList() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/businesscode/getAllBusinessCodeList",
                method: 'get',
                params: this.$http.adornParams({})
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.buinessCodeList = data.body;

                }
            })
        },
        savePersonProject(workNo) {
            this.$http({
                url: this.$store.state.httpUrl + "/business/personproject/save",
                method: 'post',
                data: this.$http.adornParams({
                    workNo: workNo,
                })
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.$message({
                        message: '关注成功',
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                            this.getDataList()
                        }
                    })
                }
            })
        },
        deletePersonProject(recId) {
            this.$http({
                url: this.$store.state.httpUrl + "/business/personproject/delete/" + recId,
                method: 'post'
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.$message({
                        message: '取关成功',
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                            this.getDataList()
                        }
                    })
                }
            })
        },
        selectedProject(workNo) {
            // 判断项目是否已设置项目类型，周报类型和标的物
            let projectKind = "";
            this.$http({
                url: this.$store.state.httpUrl + "/common/validProject/" + workNo,
                method: "get",
                params: {},
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    sessionStorage.setItem('workNo', JSON.stringify(workNo))
                    // 一级权限菜单定位到第一个
                    sessionStorage.setItem('menuIndex', 0)
                    sessionStorage.setItem('subMenuIndex', 0)
                    this.$cookie.set('showSidebarFlag', false)
                    this.getSubjectDropDown(workNo);
                    }
                });
        },
        getSubjectDropDown() {
            this.$http({
                url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
                method: "get",
                params: {},
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.subjectList = data.body;
                    this.$store.commit('common/updateSubjectList', this.subjectList)
                    if (this.subjectList.length != undefined && this.subjectList.length > 0) {
                        if ($common.getItem("subjectNo") == "" || $common.getItem("subjectNo") == undefined) {
                            $common.setItem("subjectNo", this.subjectList[0].displayValue);
                            this.$store.commit('common/updateSelectSubjectNo', this.subjectNo)
                        }
                    }
                }
                this.$router.push({ name: 'workbench' })
            });
        },
        selGetDataList() {
            this.allProjectPageIndex = 1;
            this.interestProjectPageIndex = 1;
            this.myProjectPageIndex = 1;
            this.getDataList();
        },
        handleTabClick(tab) {
            this.activeIndex = tab.name
        },
        // 每页数
        sizeChangeHandle1(val) {
            this.pageSize = val
            this.allProjectPageIndex = 1
            this.getDataList()
        },
        sizeChangeHandle2(val) {
            this.pageSize = val
            this.interestProjectPageIndex = 1
            this.getDataList()
        },
        sizeChangeHandle3(val) {
            this.pageSize = val
            this.myProjectPageIndex = 1
            this.getDataList()
        },
        // 当前页
        currentChangeHandle1(val) {
            this.allProjectPageIndex = val
            this.getDataList()
        },
        currentChangeHandle2(val) {
            this.interestProjectPageIndex = val
            this.getDataList()
        },
        // 当前页
        currentChangeHandle3(val) {
            this.myProjectPageIndex = val
            this.getDataList()
        },
        tableRowClassName({ row, rowIndex }) {
            return $common.tableRowClassName({ row, rowIndex });
        },
        setSubject(workNo) {
            sessionStorage.setItem('workNo', JSON.stringify(workNo))
            this.setSubjectVisible = true
            this.$nextTick(() => {
                this.$refs.setSubject.init()
            })
        },
        setProjectKind(workNo) {
            sessionStorage.setItem('workNo', JSON.stringify(workNo))
            this.projectKindSettingVisible = true
            this.$nextTick(() => {
                this.$refs.projectKindSetting.init()
            })
        }
    }
};
</script>
<style lang="scss" scoped="scoped">
.selItemText {
    font-size: 14px;
    color: #1c1c1c;
    display: inline-block;
    width: 80px;
}

.selItemInput1 {
    width: 180px !important;
}

.selItemInput2 {
    width: 120px !important;
}

.selItemInput3 {
    width: 220px !important;
}

.my-pagination {
    margin-left: 20px !important;
}

::v-deep(.el-table th.el-table__cell) {
    // background-color: #d3e2f4 !important;
    font-size: 15px;
    padding: 2px 0;
}

::v-deep .el-tabs__item.is-active {
    color: white;
    font-weight: bolder;
    background-color: #036EC2;
}

::v-deep .el-tabs__item:hover {
    color: #3d0d3f;
    background-color: #eceeed;
}

::v-deep .el-tabs--top .el-tabs__item.is-top {
    width: 134.5px;
    text-align: center;
    height: 40px;
}

.projectTitle {
    font-size: 22px;
    background-color: #036EC2;
    color: white;
    font-weight: bolder;
    height: 50px;
    // width: 2000px;
    padding-top: 12px;
    padding-left: 15px;
}

.clearfix {
    margin-bottom: -10px;
    font-weight: bolder;
}

.el-icon-star-off {
    color: orange;
    width: 30px;
    font-size: 20px;
    margin-left: -10px;
}

.el-icon-collection-tag {
    color: orange;
    font-size: large;
    margin-top: 3px;
}

::v-deep(.selSearchInput .el-input__inner) {
    width: 170px !important;
    height: 30px !important;
}

::v-deep(.selYearInput .el-input__inner) {
    width: 100px !important;
    height: 30px !important;
}

::v-deep(.selFormItem .el-form-item__label) {
    font-size: smaller;
}
</style>
